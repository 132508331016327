<template>
  <Layout>
    <div class="mb-3">
      <h1 class="display-3 mb-3">{{ $t("page.company.info") }}</h1>
    </div>

    <!-- <div>
      <pre>company: {{ company }}</pre>
    </div> -->
    <!-- <div>
      <pre>alert: {{ alert }}</pre>
    </div> -->

    <div class="mb-3">
      <div v-if="loading">
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </div>

      <div v-else class="card">
        <div class="card-body">
          <b-alert
            v-if="alert"
            :variant="alert?.status"
            class="mt-3"
            dismissible
            :show="Boolean(alert)"
            >{{ alert?.text }}</b-alert
          >

          <formCompany
            v-if="company"
            :company="company"
            @onSubmit="submitForm"
            inline
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import Layout from "@/views/layouts/main";
import formCompany from "@/components/admin/form-company";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.$t("page.company.info"),
    };
  },
  components: {
    Layout,
    formCompany,
  },
  data() {
    return {
      loading: false,
      alert: null,
      wait: false,
      company: null,
    };
  },
  created() {
    this.loadData(false);
  },
  methods: {
    ...mapActions(useGlobalStore, ["getCompanyList"]),
    async loadData(silent = true) {
      if (this.wait) {
        return;
      }

      this.wait = true;

      if (!silent) {
        this.loading = true;
      }

      this.alert = null;

      const req = await this.getCompanyList();
      // console.log("loadData, req.data: ", req.data);
      // console.log("loadData, req.response: ", req.response);

      if (req?.data) {
        this.company = req.data;
      } else {
        this.alert = {
          status: "danger",
          text: this.$t("msg.error"),
        };

        if (req.response?.status && req.response?.data?.message) {
          this.alert.text = `[${req.response.status}] ${req.response.data.message}`;
        }
      }

      this.loading = false;
      this.wait = false;
    },
    async submitForm() {
      await this.loadData();

      this.alert = {
        status: "success",
        text: this.$t("msg.data-update-success"),
      };
    },
  },
};
</script>
<style scoped lang="scss"></style>
